import React from 'react';

import styled from 'styled-components';

import Layout from '../components/layout';

import pastMasters from '../components/pastMasters';

const Container = styled.div`
  padding: 2rem;
  text-align: center;
  width: 100%;
`;

const StyledList = styled.ul`
  list-style: none;
`;
const StyledItem = styled.li`
  margin-bottom: 1rem;
`;
export default function PastMasters() {
  return (
    <Layout>
      <Container>
        <StyledList>
          {pastMasters
            .slice()
            .reverse()
            .map((master) => (
              <StyledItem>
                <strong>
                  {master.PDDGM && '†'}
                  {master.Deceased && '*'}
                  {master.First} {master.Middle} {master.Last}
                </strong>
                <div>{master.Years.join(', ')}</div>
              </StyledItem>
            ))}
        </StyledList>
      </Container>
    </Layout>
  );
}

export default [
  {
    Id: 1,
    First: 'William',
    Middle: '',
    Last: 'Stitt',
    Suffix: '',
    Deceased: true,
    PDDGM: true,
    UD: true,
    Years: ['1915-1916', '1917-1918'],
  },
  {
    Id: 2,
    First: 'Lyman',
    Middle: 'C.',
    Last: 'Bauldry',
    Suffix: '',
    Deceased: true,
    PDDGM: true,
    UD: false,
    Years: ['1916-1917'],
  },
  {
    Id: 3,
    First: 'Elton',
    Middle: 'S.',
    Last: 'Wilde',
    Suffix: '',
    Deceased: true,
    PDDGM: true,
    UD: false,
    Years: ['1918-1919'],
  },
  {
    Id: 4,
    First: 'Hiram',
    Middle: 'A.',
    Last: 'Linfield',
    Suffix: '',
    Deceased: true,
    PDDGM: true,
    UD: false,
    Years: ['1919-1920', '1947-1948'],
  },
  {
    Id: 5,
    First: 'Seth',
    Middle: 'B.J.',
    Last: 'Besse',
    Suffix: '',
    Deceased: true,
    PDDGM: true,
    UD: false,
    Years: ['1920-1921'],
  },
  {
    Id: 6,
    First: 'Charles',
    Middle: 'R.',
    Last: 'Gidley',
    Suffix: '',
    Deceased: true,
    PDDGM: false,
    UD: false,
    Years: ['1921-1922'],
  },
  {
    Id: 7,
    First: 'John',
    Middle: 'M.',
    Last: 'Lancaster',
    Suffix: '',
    Deceased: true,
    PDDGM: false,
    UD: false,
    Years: ['1922-1923'],
  },
  {
    Id: 8,
    First: 'George',
    Middle: 'H.',
    Last: 'Nye',
    Suffix: '',
    Deceased: true,
    PDDGM: false,
    UD: false,
    Years: ['1923-1924'],
  },
  {
    Id: 9,
    First: 'Francis',
    Middle: 'C.',
    Last: 'Rogerson',
    Suffix: '',
    Deceased: true,
    PDDGM: false,
    UD: false,
    Years: ['1924-1925'],
  },
  {
    Id: 10,
    First: 'Frederick',
    Middle: 'L.',
    Last: 'Woodcock',
    Suffix: '',
    Deceased: true,
    PDDGM: false,
    UD: false,
    Years: ['1925-1926'],
  },
  {
    Id: 11,
    First: 'S.',
    Middle: 'George',
    Last: 'Davenport',
    Suffix: '',
    Deceased: true,
    PDDGM: true,
    UD: false,
    Years: ['1926-1927'],
  },
  {
    Id: 12,
    First: 'Harold',
    Middle: '',
    Last: 'Ashworth',
    Suffix: '',
    Deceased: true,
    PDDGM: false,
    UD: false,
    Years: ['1927-1928'],
  },
  {
    Id: 13,
    First: 'Edmund',
    Middle: 'H.',
    Last: 'Leland',
    Suffix: '',
    Deceased: true,
    PDDGM: false,
    UD: false,
    Years: ['1928-1929'],
  },
  {
    Id: 14,
    First: 'Albert',
    Middle: 'E.',
    Last: 'McGrath',
    Suffix: '',
    Deceased: true,
    PDDGM: false,
    UD: false,
    Years: ['1929-1930'],
  },
  {
    Id: 15,
    First: 'Morgan',
    Middle: 'E.',
    Last: 'Jennings',
    Suffix: '',
    Deceased: true,
    PDDGM: false,
    UD: false,
    Years: ['1930-1931'],
  },
  {
    Id: 16,
    First: 'William',
    Middle: 'W.',
    Last: 'Batcheider',
    Suffix: '',
    Deceased: true,
    PDDGM: false,
    UD: false,
    Years: ['1931-1932'],
  },
  {
    Id: 17,
    First: 'William',
    Middle: 'B.',
    Last: 'Richie',
    Suffix: '',
    Deceased: true,
    PDDGM: false,
    UD: false,
    Years: ['1932-1933', '1933-1934'],
  },
  {
    Id: 18,
    First: 'William',
    Middle: 'B.',
    Last: 'Perry',
    Suffix: 'Jr.',
    Deceased: true,
    PDDGM: false,
    UD: false,
    Years: ['1934-1935'],
  },
  {
    Id: 19,
    First: 'William',
    Middle: 'O.',
    Last: 'Rowand',
    Suffix: '',
    Deceased: true,
    PDDGM: true,
    UD: false,
    Years: ['1935-1936'],
  },
  {
    Id: 20,
    First: 'Thomas',
    Middle: 'W.',
    Last: 'Williams',
    Suffix: '',
    Deceased: true,
    PDDGM: false,
    UD: false,
    Years: ['1936-1937', '1937-1938'],
  },
  {
    Id: 21,
    First: 'Rudolph',
    Middle: 'H.',
    Last: 'Brenneke',
    Suffix: '',
    Deceased: true,
    PDDGM: false,
    UD: false,
    Years: ['1938-1939', '1939-1940'],
  },
  {
    Id: 22,
    First: 'Clayton',
    Middle: '',
    Last: 'Taylor',
    Suffix: 'Sr.',
    Deceased: true,
    PDDGM: false,
    UD: false,
    Years: ['1940-1941', '1941-1942', '1942-1943'],
  },
  {
    Id: 23,
    First: 'G.',
    Middle: 'Warren',
    Last: 'Hirshon',
    Suffix: '',
    Deceased: true,
    PDDGM: false,
    UD: false,
    Years: ['1943-1944', '1944-1945'],
  },
  {
    Id: 24,
    First: 'Robert',
    Middle: 'K.',
    Last: 'Gardner',
    Suffix: '',
    Deceased: true,
    PDDGM: false,
    UD: false,
    Years: ['1945-1946'],
  },
  {
    Id: 25,
    First: 'James',
    Middle: 'E.',
    Last: 'Balloch',
    Suffix: '',
    Deceased: true,
    PDDGM: false,
    UD: false,
    Years: ['1946-1947'],
  },
  {
    Id: 26,
    First: 'Harold',
    Middle: '',
    Last: 'Symes',
    Suffix: '',
    Deceased: true,
    PDDGM: false,
    UD: false,
    Years: ['1948-1949', '1949-1950'],
  },
  {
    Id: 27,
    First: 'Thomas',
    Middle: 'C.',
    Last: 'Weaver',
    Suffix: '',
    Deceased: true,
    PDDGM: false,
    UD: false,
    Years: ['1950-1951'],
  },
  {
    Id: 28,
    First: 'Jack',
    Middle: '',
    Last: 'Turner',
    Suffix: '',
    Deceased: true,
    PDDGM: false,
    UD: false,
    Years: ['1951-1952'],
  },
  {
    Id: 29,
    First: 'I.',
    Middle: 'Arthur',
    Last: 'Crapo',
    Suffix: '',
    Deceased: true,
    PDDGM: false,
    UD: false,
    Years: ['1952-1953'],
  },
  {
    Id: 30,
    First: 'Charles',
    Middle: 'H.',
    Last: 'Wardwell',
    Suffix: '',
    Deceased: true,
    PDDGM: false,
    UD: false,
    Years: ['1953-1954'],
  },
  {
    Id: 31,
    First: 'Linville',
    Middle: 'A.',
    Last: 'Slone',
    Suffix: '',
    Deceased: true,
    PDDGM: true,
    UD: false,
    Years: ['1954-1955', '1973-1974'],
  },
  {
    Id: 32,
    First: 'Joseph',
    Middle: 'W.',
    Last: 'Wright',
    Suffix: '',
    Deceased: true,
    PDDGM: false,
    UD: false,
    Years: ['1955-1956', '1956-1957'],
  },
  {
    Id: 33,
    First: 'R.',
    Middle: 'Eliot',
    Last: 'Carruth',
    Suffix: '',
    Deceased: true,
    PDDGM: false,
    UD: false,
    Years: ['1957-1958'],
  },
  {
    Id: 34,
    First: 'William',
    Middle: 'H.S.',
    Last: 'Preece',
    Suffix: '',
    Deceased: true,
    PDDGM: false,
    UD: false,
    Years: ['1958-1959'],
  },
  {
    Id: 35,
    First: 'Donald',
    Middle: 'H.',
    Last: 'Rex',
    Suffix: '',
    Deceased: true,
    PDDGM: false,
    UD: false,
    Years: ['1959-1960'],
  },
  {
    Id: 36,
    First: 'George',
    Middle: 'T.P.',
    Last: 'Mitchell',
    Suffix: '',
    Deceased: true,
    PDDGM: false,
    UD: false,
    Years: ['1960-1961'],
  },
  {
    Id: 37,
    First: 'Laurence',
    Middle: 'A.',
    Last: 'Wescott',
    Suffix: '',
    Deceased: false,
    PDDGM: false,
    UD: false,
    Years: ['1961-1962', '1962-1963'],
  },
  {
    Id: 38,
    First: 'A.',
    Middle: 'Malcolm',
    Last: 'Mager',
    Suffix: '',
    Deceased: true,
    PDDGM: false,
    UD: false,
    Years: ['1963-1964'],
  },
  {
    Id: 39,
    First: 'Lynde',
    Middle: 'G.',
    Last: 'Williams',
    Suffix: '',
    Deceased: true,
    PDDGM: false,
    UD: false,
    Years: ['1964-1965'],
  },
  {
    Id: 40,
    First: 'Alwyn',
    Middle: '',
    Last: 'Ainsley',
    Suffix: '',
    Deceased: true,
    PDDGM: false,
    UD: false,
    Years: ['1965-1966', '1966-1967'],
  },
  {
    Id: 41,
    First: 'George',
    Middle: 'R.',
    Last: 'Dewhurst',
    Suffix: '',
    Deceased: true,
    PDDGM: true,
    UD: false,
    Years: ['1967-1968'],
  },
  {
    Id: 42,
    First: 'Wilbur',
    Middle: 'W.',
    Last: 'Woodman',
    Suffix: '',
    Deceased: true,
    PDDGM: false,
    UD: false,
    Years: ['1968-1969'],
  },
  {
    Id: 43,
    First: 'Dana',
    Middle: 'P.',
    Last: 'Wilkins',
    Suffix: '',
    Deceased: true,
    PDDGM: false,
    UD: false,
    Years: ['1969-1970'],
  },
  {
    Id: 44,
    First: 'Robert',
    Middle: 'G.',
    Last: 'Howard',
    Suffix: 'Jr.',
    Deceased: true,
    PDDGM: false,
    UD: false,
    Years: ['1970-1971'],
  },
  {
    Id: 45,
    First: 'Clayton',
    Middle: '',
    Last: 'Taylor',
    Suffix: 'Jr.',
    Deceased: true,
    PDDGM: false,
    UD: false,
    Years: ['1971-1972', '1972-1973'],
  },
  {
    Id: 46,
    First: 'John',
    Middle: 'R.',
    Last: 'Gardner',
    Suffix: '',
    Deceased: false,
    PDDGM: false,
    UD: false,
    Years: ['1974-1975'],
  },
  {
    Id: 47,
    First: 'Franklin',
    Middle: 'H.',
    Last: 'Michelsen',
    Suffix: '',
    Deceased: true,
    PDDGM: true,
    UD: false,
    Years: ['1975-1976'],
  },
  {
    Id: 48,
    First: 'Richard',
    Middle: 'W.',
    Last: 'Taylor',
    Suffix: '',
    Deceased: true,
    PDDGM: true,
    UD: false,
    Years: ['1976-1977', '1977-1978'],
  },
  {
    Id: 49,
    First: 'Bruce',
    Middle: 'E.',
    Last: 'Rex',
    Suffix: '',
    Deceased: true,
    PDDGM: false,
    UD: false,
    Years: ['1978-1979', '1979-1980', '1984-1985'],
  },
  {
    Id: 50,
    First: 'Kenneth',
    Middle: 'T.',
    Last: 'Ainsley',
    Suffix: '',
    Deceased: false,
    PDDGM: false,
    UD: false,
    Years: ['1980-1981', '1981-1982'],
  },
  {
    Id: 51,
    First: 'Kenneth',
    Middle: 'C.',
    Last: 'Russell',
    Suffix: '',
    Deceased: false,
    PDDGM: false,
    UD: false,
    Years: ['1982-1983', '1983-1984'],
  },
  {
    Id: 52,
    First: 'Antone',
    Middle: 'J.',
    Last: 'Bettencourt',
    Suffix: '',
    Deceased: true,
    PDDGM: true,
    UD: false,
    Years: ['1985-1986', '1986-1987', '1987-1988'],
  },
  {
    Id: 53,
    First: 'Rodman',
    Middle: 'E.',
    Last: 'Taylor',
    Suffix: '',
    Deceased: false,
    PDDGM: false,
    UD: false,
    Years: ['1988-1989', '1989-1990'],
  },
  {
    Id: 54,
    First: 'Robert',
    Middle: 'B.',
    Last: 'Sisson',
    Suffix: '',
    Deceased: true,
    PDDGM: true,
    UD: false,
    Years: ['1990-1991', '1991-1992'],
  },
  {
    Id: 55,
    First: 'Gerald',
    Middle: 'F.',
    Last: 'Huston',
    Suffix: 'Sr.',
    Deceased: false,
    PDDGM: false,
    UD: false,
    Years: ['1992-1993', '1993-1994'],
  },
  {
    Id: 56,
    First: 'George',
    Middle: 'J.P.',
    Last: 'Breault',
    Suffix: '',
    Deceased: false,
    PDDGM: true,
    UD: false,
    Years: ['1994-1995', '1995-1996'],
  },
  {
    Id: 57,
    First: 'Gilbert',
    Middle: 'G.',
    Last: 'Stiles',
    Suffix: '',
    Deceased: true,
    PDDGM: false,
    UD: false,
    Years: ['1996-1997', '1997-1998'],
  },
  {
    Id: 58,
    First: 'Peter',
    Middle: 'W.',
    Last: 'Ham',
    Suffix: '',
    Deceased: false,
    PDDGM: false,
    UD: false,
    Years: ['1998-1999', '1999-2000'],
  },
  {
    Id: 59,
    First: 'Allen',
    Middle: 'F.',
    Last: 'Tabor',
    Suffix: '',
    Deceased: true,
    PDDGM: false,
    UD: false,
    Years: ['2000-2001', '2001-2002'],
  },
  {
    Id: 60,
    First: 'William',
    Middle: 'W.',
    Last: 'Taylor',
    Suffix: '',
    Deceased: false,
    PDDGM: false,
    UD: false,
    Years: ['2002-2003', '2003-2004'],
  },
  {
    Id: 61,
    First: 'Harold',
    Middle: 'L.',
    Last: 'Gerard',
    Suffix: 'Jr.',
    Deceased: false,
    PDDGM: true,
    UD: false,
    Years: ['2004-2005', '2005-2006'],
  },
  {
    Id: 62,
    First: 'Robert',
    Middle: 'K.',
    Last: 'Gardner',
    Suffix: 'Jr.',
    Deceased: false,
    PDDGM: false,
    UD: false,
    Years: ['2006-2007', '2007-2008'],
  },
  {
    Id: 63,
    First: 'Thomas',
    Middle: 'M.',
    Last: 'Brooks',
    Suffix: 'Jr.',
    Deceased: false,
    PDDGM: false,
    UD: false,
    Years: ['2008-2009'],
  },
  {
    Id: 64,
    First: 'August',
    Middle: 'R.',
    Last: 'Cote',
    Suffix: '',
    Deceased: false,
    PDDGM: false,
    UD: false,
    Years: ['2009-2010', '2010-2011'],
  },
  {
    Id: 65,
    First: 'Randall',
    Middle: 'W.',
    Last: 'Whittle',
    Suffix: '',
    Deceased: false,
    PDDGM: false,
    UD: false,
    Years: ['2011-2012', '2012-2013'],
  },
  {
    Id: 66,
    First: 'Scott',
    Middle: 'T.',
    Last: 'Brooks',
    Suffix: '',
    Deceased: false,
    PDDGM: false,
    UD: false,
    Years: ['2013-2014', '2014-2015'],
  },
  {
    Id: 67,
    First: 'Kenneth',
    Middle: 'A.',
    Last: 'Gilbert',
    Suffix: '',
    Deceased: false,
    PDDGM: false,
    UD: false,
    Years: ['2015-2016', '2016-2017'],
  },
  {
    Id: 68,
    First: 'Charles',
    Middle: 'E.',
    Last: 'Katsanos',
    Suffix: '',
    Deceased: false,
    PDDGM: true,
    UD: false,
    Years: ['2017-2018', '2018-2019'],
  },
  {
    Id: 69,
    First: 'Emmanuel',
    Middle: 'R.',
    Last: 'Fernandes',
    Suffix: '',
    Deceased: false,
    PDDGM: false,
    UD: false,
    Years: ['2019-2020'],
  },
];
